<template>
  <div class="nav">
    <el-menu :default-active="activePath" class="navbar" mode="horizontal" background-color="#3494ec" text-color="#fff"
      active-text-color="#40bcff">
      <!-- 遍历 navlist 生成菜单项 -->
      <el-menu-item v-for="(item, index) in navlist" :key="index" :index="item.path" :route="item.path"
        @click="$router.push(item.path)">
        {{ item.title }}
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      navlist: [
        { title: "首页", path: "/" },
        { title: "广西招投标公告", path: "/invitation" },
        { title: "西部CA办理", path: "/camodule" },
        { title: "服务项目", path: "/projectservices" },
        { title: "新闻资讯", path: "/news" }, // 父路径
        { title: "关于我们", path: "/about" }, // 可恢复注释
      ],
    };
  },
  computed: {
    activePath() {
      const newsPaths = ["/AllNews", "/GroupNews", "/TradeNews", "/MediaCoverage"];
      return newsPaths.includes(this.$route.path)
        ? "/news" // 当访问子路径时，父路径激活
        : this.$route.path;
    },
  },
  methods: {
    funyuming(index, item) {
      this.current = index;
      this.$router.push(item);
      // console.log(this.navIndex);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped lang="less">
// 导航样式
.nav {
  width: 100%;
  height: 70px;
  background-color: #3494ec;
  min-width: 1300px;

  //   position: absolute;
  //   bottom: 0;
  //   position: relative;
  .navbar {
    display: flex;
    color: #eeeded;
    font-size: 28px;
    font-weight: 700;
    // line-height: 70px;
    height: 70px;
    align-items: center;
    width: 1300px;
    margin: 0 auto;
    justify-content: space-evenly;

    // justify-content: center;
    .navtype {
      color: #fff;
      text-decoration: none;
    }

    .nav-meun {

      // padding: 0 10px;
      //   margin-right: 20px;
      // &.active {
      //   background-color: #40bcff;
      //   color: #fff;
      // }
      &:hover {
        font-size: 29px;
      }
    }

    .nav-meun:hover {
      // background-color: #40bcff;
      // color: #fff;
      // padding: 16px 20px;
      // text-decoration: none;
    }
  }
}

.active {
  background-color: #40bcff;
  padding: 16px 20px;
}

.nav {
  width: 100%;
  min-width: 1300px;
}

.el-menu {
  height: 70px;
  line-height: 70px !important; // 覆盖行高

  .el-menu-item {
    font-size: 28px;
    font-weight: 700;

    &:hover {
      font-size: 29px; // 保持悬停效果
    }
  }
}
.el-menu-item{
  margin: 0 !important;
  height: 100% !important;
  padding-top: 3px;
}
.el-menu--horizontal>.el-menu-item.is-active {
  color: white !important;
  border-bottom: none !important;
  background-color: rgba(112, 184, 255, 0.6) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
</style>
