<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>通知公告列表</span></div>
    </div>
    <!-- 幼儿板块 -->
    <div class="Young">
      <div class="Young_cont">
        <h1>通知公告列表</h1>
        <div class="notice-content" v-loading="loading">
          <div
            v-for="(item, index) in infolist"
            :key="index"
            @click="getnoticeDetail(item.id)"
            class="notice-item"
          >
            <div class="title">
              <span class="cirle"> </span>
              <div class="titlename">{{ item.title }}</div>
            </div>
            <div class="dataTime">{{ item.releaseTime }}</div>
          </div>
        </div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import article from "@/api/article";
export default {
  name: "noticelis",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "通知公告列表",
      Img1: require("../assets/images/newsbanner.jpg"),
      infolist: [],
    };
  },
  mounted() {
    this.getInfolist();
  },
  methods: {
    getInfolist() {
      this.loading = true;
      article
        .noticeList()
        .then((res) => {
          // console.log(res, "444444");
          this.infolist = res.data.data.records.slice(0, 5);
          this.infolist.forEach((item) => {
            item.releaseTime = item.releaseTime.slice(0, 11);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getnoticeDetail(id) {
      this.$router.push({
        path: "/noticedetail",
        query: { id },
      });
    },
  },
};
</script>
<style scoped lang="less">
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}
/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}
.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
/* 幼儿板块 */
.Young {
  width: 100vw;
  height: 700px;
}
.Young_cont {
  width: 960px;
  margin: 0 auto;
}
.Young_cont > h1 {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
  text-align: center;
  margin-top: 40px;
  // border-bottom: 1px solid #666;
}
.notice-content {
  padding: 20px;
  background: #fafafa;
  //   padding: 30px;
  margin-top: 50px;
  border-radius: 15px;
  .notice-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
      text-align: left;
      .titlename {
        width: 200px;
      }
      .cirle {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #727272;
        //   border-radius: 50%;
        line-height: 28px;
        margin-right: 20px;
      }
      .dataTime {
        font-size: 16px;
        color: #222121;
      }
    }
  }
}
</style>
