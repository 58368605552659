<template>
  <div class="kefu">
    <div class="pic" @click="scrollToTop">
      <img src="./../assets/images/huidaodingbu.png" />
    </div>
  </div>
</template>
<script>
import article from "@/api/article";
export default {
  name: "service",
  data() {
    return {
      showImageModal: false,
    };
  },
  mounted() {
  },
  methods: {
    scrollToTop() {
      const total = window.scrollY;
      const step = (pos) => {
        const stepSize = Math.max(100, pos * 0.1); // 每次移动剩余距离的10%
        window.scrollTo(0, pos - stepSize);
        if (pos > 0) requestAnimationFrame(() => step(pos - stepSize));
      };
      step(total);
    }
  }
};
</script>
<style scoped lang="less">
.kefu {
  width: 66px;
  height: 66px;
  background-color: #ffffff;
  position: fixed;
  bottom: 30%;
  right: 60px;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0 0 20px rgba(52, 148, 236, 0.8);
  font-size: 0;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 15px rgba(52, 148, 236, 0.9);
  }

  .pic {
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}
</style>