<template>
  <table class="business" border="1">
    <tbody>
      <tr>
        <td colspan="5">现场办理</td>
      </tr>
      <tr>
        <td colspan="3">地址</td>
        <td>营业时间</td>
        <td>联系方式</td>
      </tr>
      <tr>
        <td width="80">南宁</td>
        <td colspan="2">
          南宁市青秀区中柬路8号龙光世纪2号（B座）楼六层610号办公室
        </td>
        <td>
          <p>周一至周五</p>
          <p>8:30-12:00</p>
          <p>14:00-18:00</p>
        </td>
        <td>
          <p>0771-5770288</p>
          <p>17736600139（微信客服同号）</p>
        </td>
      </tr>
      <tr>
        <td width="80">柳州</td>
        <td colspan="2">柳州市民服务中心北楼7楼前台（广西柳州市龙湖路13号）</td>
        <td>
          <p>周一至周五</p>
          <p>9:00-12:00</p>
          <p>13:00-16:30</p>
        </td>
        <td>17777182557（微信客服同号）</td>
      </tr>
      <tr>
        <td colspan="5">线上办理</td>
      </tr>
      <tr>
        <td colspan="5">
          <p> <span class="outer_join_btn" @click="junpOpen('https://gxcg.cwca.com.cn')">线上自助平台：https://gxcg.cwca.com.cn</span></p>
          <p>账号：统一社会信用代码（首次使用需注册）</p>
          <p>密码：（用户注册时自行设置）</p>
        </td>
      </tr>
    </tbody>
  </table>
</template>
  
  <script>
export default {
  name: "handlingMethod",
  data() {
    return {};
  },
  methods: {
    junpOpen(url) {
      window.open(url, "_blank");
    }
  },
};
</script>
  <style scoped lang="less">
.business {
  font-size: 16px;
  color: #333333;
  td {
    text-align: center;
    padding: 20px 10px;
  }
  p {
    margin: 8px 0;
  }
  .outer_join_btn {
    color:#3494ec ;
    cursor: pointer;
  }
}
</style>
  