<!-- star -->
<template>
  <div class="youjiaopage_wrap">
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>广西招投标公告</span></div>
    </div>
    <div class="main">
      <section class="content-wrapper">
        <div class="container">
          <div style="margin-bottom: 20px">
            <el-form
              ref="form"
              :model="form"
              label-width="80px"
              style="width: 800px; margin: 0 auto"
            >
              <!--            <el-input  v-model="form.name" ></el-input>-->
              <!--            <el-input v-show="caigoutable" v-model="form.name" ></el-input>-->
              <el-input
                v-if="caigoulist"
                placeholder="搜索公告信息"
                v-model="inputSearchList"
                class="input-with-select"
                size="80"
                clearable
              >
                <el-button
                  type="primary"
                  slot="append"
                  size="medium"
                  icon="el-icon-search"
                  style="padding-left: 10px"
                  @click="caiGouListSearch"
                  >搜索</el-button
                >
              </el-input>

              <el-input
                v-if="caigoutable"
                placeholder="搜索政府采购公告信息"
                v-model="inputSearchTable"
                class="input-with-select"
                clearable
              >
                <el-button
                  type="primary"
                  slot="append"
                  size="medium"
                  icon="el-icon-search"
                  style="padding-left: 10px"
                  @click="caiGouTableSearch"
                  >搜索</el-button
                >
              </el-input>
            </el-form>
          </div>
          <div class="row">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="采购公告信息" name="first">
                <CaiGouListContents
                  :searchString="searchListString"
                ></CaiGouListContents>
              </el-tab-pane>
              <el-tab-pane label="政府采购意向" name="second">
                <CaiGouTableContents
                  :searchString="searchTableString"
                ></CaiGouTableContents>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </section>
    </div>
    <service></service>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import service from "@/components/service.vue";
import cailist from "./procure/cailist.vue";
import caitable from "./procure/caitable.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
    service,
    CaiGouListContents: cailist,
    CaiGouTableContents: caitable,
  },
  data() {
    return {
      h1: "广西招投标公告",
      Img1: require("../assets/images/newsbanner.jpg"),
      p1: "了解更多最新招投标信息",
      activeName: "first",
      // 遮罩层
      loading: true,
      caigoulist: false,
      caigoutable: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      inputSearchList: null,
      inputSearchTable: null,
      // 总条数
      total: 0,
      // 政府采购意向表格数据
      caigoutableList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      tabsIndex: 0,
      searchTableString: null,
      searchListString: null,
      // 查询参数
      query: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
    };
  },
  created() {
    this.caigoulist = true;
  },
  methods: {
    handleClick(tab) {
      // console.log(tab.index);
      this.tabsIndex = parseInt(tab.index);
      switch (this.tabsIndex) {
        case 0:
          this.caigoulist = true;
          this.caigoutable = false;

          break;
        case 1:
          this.caigoulist = false;
          this.caigoutable = true;

          break;
      }
    },

    commentAdd() {},
    caiGouListSearch() {
      this.searchListString = this.inputSearchList;
      // console.log(this.searchListString, "55555");
    },
    caiGouTableSearch() {
      this.searchTableString = this.inputSearchTable;
    },
  },
};
</script>
<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}
.Crumbs span {
  color: #000000;
}
.main {
  /* width: 100vw; */
  width: 1200px;
  padding-top: 30px;
  margin: 0 auto;
  height: 800px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
