<template>
  <div>
    <banner2 :bannerImg="Img1"></banner2>
    <nav2></nav2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div>当前位置：首页><span>广西招投标公告</span>><span>采购公告信息</span></div>
    </div>
    <section class="content-wrapper">
      <div class="container" v-loading="loading">
        <div style="margin-bottom: 20px" class="main">
          <h1>{{ caigouInfo.title }}</h1>
          <div class="source">
            <span>来源：{{ caigouInfo.author }}</span>
            <span>发布时间{{ caigouInfo.publishDate }}</span>
          </div>
        </div>

        <div class="row">
          <div v-html="caigouInfo.content" @click="handleClick"></div>
        </div>

        <div>
          广西政府采购网官方公告：<a
            :href="govHttpAddress"
            style="color: #3494ec"
            target="_blank"
            >原文地址</a
          >
        </div>
      </div>
    </section>

    <section id="team"></section>
    <ToTop></ToTop>
    <service></service>
    <!-- content-wrapper -->
    <Footer></Footer>
  </div>
</template>

<script>
import service from "@/components/service.vue";
import Footer from "@/components/footer.vue";
import article from "@/api/article";
import banner2 from "@/components/banner.vue";
import nav2 from "@/components/nav.vue";
import ToTop from "@/components/toTop.vue";
// import axios from "axios";
import axios from "axios";

export default {
  name: "listinfo",
  components: {
    Footer,
    nav2,
    banner2,
    service,
    ToTop
  },
  data() {
    return {
      h1: "公告详情",
      Img1: require("@/assets/images/banner1.png"),
      p1: "更多最新精彩资讯，感受企业魅力",
      infoParams: {},
      caigouInfo: {
        pageNum: 1,
        pageSize: 10,
        articleId: null,
      },
      // id:''
      govHttpAddress: "",
      startTime: 0, // 记录页面开始时间
      stayTime: 0, // 记录页面停留时间
    };
  },
  created() {
    if (this.$route.query) {
      this.infoParams = this.$route.query;
      console.log("infoParams", this.infoParams);
      let articleId = this.$route.query.articleId;
      this.getList(articleId);
    }
  },
  mounted() {
    this.getIP()
    // 页面加载时设置开始时间
    this.startTime = new Date().getTime();
  },
  beforeDestroy() {
    // 页面销毁前计算停留时间
    const endTime = new Date().getTime();
    this.stayTime = Math.floor((endTime - this.startTime) / 1000) ;
    console.log(`页面停留时间：${this.stayTime}毫秒`);
    console.log(`ip地址`,this.ip);
    article.browseRecord({
      plateName: '广西招投标项目信息',
      title: this.caigouInfo.title,
      downName: '',
      clickNumber: 1,
      browseTime: this.stayTime,
      isattach: 2,
      areaIp: this.ip || '',
      equipment: '',
    }).then((res) => {})
  },
  methods: {
    getList(articleId) {
      this.loading = true;
      article.getDetail({ articleId: articleId }).then((res) => {
        this.caigouInfo = res.data.data;
        var temptag =
          "http://zfcg.gxzf.gov.cn/site/detail?parentId=66485&articleId=" +
          this.caigouInfo.articleId +
          "&utm=site.site-PC-38919.1024-pc-wsg-secondLevelPage-front.1.5320e5d0c37c11ee8334dd6d4134a42f";
        this.govHttpAddress = temptag;
        this.loading = false;
      });
    },
    getIP() {
      // 使用公共的IP信息获取服务
      axios.get('https://api.ipify.org?format=json').then(data => {
          console.log('IP',data.data.ip)
          this.ip = data.data.ip;
        })
        .catch(error => {
          console.error('获取IP地址失败:', error);
          this.ip = '无法获取';
        });
    },
    // 获取点击附件下载
    handleClick(e) {
      let clickElement = e.target
      if(clickElement.tagName === 'A') {
        article.browseRecord({
          plateName: '广西招投标项目信息',
          title: this.caigouInfo.title,
          downName: clickElement.innerHTML,
          clickNumber: 1,
          browseTime: 1,
          isattach: 1,
          areaIp: this.ip || '',
          equipment: '',
        }).then((res) => {})
      }
    },
    browseRecord() {

    }
  },
};
</script>
<style scoped>
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

.content-wrapper {
  width: 1100px;
  margin: 0 auto;
}
.main {
  border-bottom: 1px dotted #000;
  padding-bottom: 20px;
}
.main h1 {
  text-align: center;
  padding: 30px 0;
}
.main .source {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  color: #333;
}
a {
  color: #3494ec;
}
</style>
